<div [className]="isReview ? 'hidden' : ''">
  <h3 class="section-header">Payment Details</h3>

  <app-recurly-form #recurlyForm [formGroup]="cardDetailsForm">
    <div class="credit-card-wrapper">
      <app-recurly-control
        appRecurlyControlValidator="recurly"
        class="credit-card"
        type="card"
        formControlName="cardDetails"
      ></app-recurly-control>
    </div>

    <ng-container *ngTemplateOutlet="tplCreditCardErrors"></ng-container>

    <input type="hidden" data-recurly="first_name" [value]="billingAddress?.firstName || ''" />
    <input type="hidden" data-recurly="last_name" [value]="billingAddress?.lastName || ''" />
    <input type="hidden" data-recurly="address1" [value]="billingAddress?.address1 || ''" />
    <input type="hidden" data-recurly="city" [value]="billingAddress?.city || ''" />
    <input type="hidden" data-recurly="country" [value]="billingAddress?.country || 'US'" />
    <input type="hidden" data-recurly="state" [value]="billingAddress?.state || ''" />
    <input type="hidden" data-recurly="postal_code" [value]="billingAddress?.postalCode || ''" />
  </app-recurly-form>

  <label class="app-checkbox same-shipping-checkbox">
    <input type="checkbox" tabindex="-1" [checked]="useSameAddress" (change)="onUseSameAddressChange($event)" />
    <div class="app-checkbox-icon"></div>
    Billing address same as shipping address
  </label>

  <span class="ach-payment-msg"
    >We do not accept pre-paid cards. To make payment via ACH, please email:
    <a href="mailto:sales@forcefleettracking.com">sales@forcefleettracking.com</a></span
  >

  <app-address-form *ngIf="!useSameAddress"></app-address-form>

  <div class="actions">
    <button class="btn-primary" [disabled]="!orderState.isOrderDetailsValid()" (click)="reviewOrder()">
      Review Order
    </button>
  </div>
</div>

<ng-container *ngIf="isReview">
  <div class="terms-agreement-msg">
    <label>
      By selecting <b>Pay Now</b>, you agree and consent to the Force Fleet Tracking
      <a target="_blank" href="https://forcefleettracking.com/terms/">Terms of Service</a>
      and
      <a target="_blank" href="https://forcefleettracking.com/privacy/">Privacy Policy</a>
    </label>
  </div>

  <div class="actions">
    <button class="btn-primary" [disabled]="reCaptchaFailed || orderCompleted" (click)="onPayPressed()">Pay Now</button>
  </div>

  <div class="retry-msg" *ngIf="isReview && reCaptchaFailed">Please try again in {{ counter }} sec</div>
</ng-container>

<ng-template #tplCreditCardErrors>
  <div [formGroup]="cardDetailsForm" appFormError="cardDetails" errorName="recurly" class="cc-error">
    <div *ngIf="cardDetailsForm.controls.cardDetails.errors?.recurly?.remote?.number">
      Credit card number
      {{ cardDetailsForm.controls.cardDetails.errors?.recurly?.remote?.number }}
    </div>
    <div *ngIf="cardDetailsForm.controls.cardDetails.errors?.recurly?.remote?.cvv">
      Credit card cvv
      {{ cardDetailsForm.controls.cardDetails.errors?.recurly?.remote?.cvv }}
    </div>
    <div *ngIf="cardDetailsForm.controls.cardDetails.errors?.recurly?.remote?.expiry">
      Credit card expiry
      {{ cardDetailsForm.controls.cardDetails.errors?.recurly?.remote?.expiry }}
    </div>
    <div *ngIf="cardDetailsForm.controls.cardDetails.errors?.recurly?.remote?.year">
      Year
      {{ cardDetailsForm.controls.cardDetails.errors?.recurly?.remote?.year }}
    </div>
    <div *ngIf="!cardDetailsForm.controls.cardDetails.errors?.recurly?.number?.valid">
      Please provide a valid credit card number.
    </div>
    <div *ngIf="!cardDetailsForm.controls.cardDetails.errors?.recurly?.expiry?.valid">
      Please provide a valid expiry date.
    </div>
    <div *ngIf="!cardDetailsForm.controls.cardDetails.errors?.recurly?.cvv?.valid">Please provide a valid CVV.</div>
  </div>
  <div *ngIf="errorTransactionMsg && vendorCodeError" class="transaction-error">{{ errorTransactionMsg }}</div>
</ng-template>
