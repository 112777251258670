import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  switchMap,
  tap,
  throttleTime,
} from 'rxjs/operators';
import { ITaxesRate, RecurlyService } from 'src/app/shared/components/recurly/services/recurly.service';
import { PriceLoader } from '../loaders/price.loader';
import {
  IAddressDetails,
  ICableCartItem,
  TBillingPeriod,
  TForcePlanType,
  TPlanType,
  TPlanTypePath,
} from '../models/i-order-state.model';
import { IProductModel, IVendorCodeData, TPlanTypeModel } from '../models/ibase-price.model';
import {
  IPromoCodeFixedDiscount,
  IPromoCodePercentageDiscount,
  IPromoCodeTrialFree,
  TPromoCodeModel,
} from '../models/ipromo-code.model';
import { IHeavyDutyCable, IHeavyDutyCablesByMake } from '../models/i-order-state.model';
import { IDropdownItem } from 'src/app/shared/components/select-dropdown/select-dropdown.component';
import { uppercaseFirstChars } from 'src/app/utils/string';
import { TokenPayload } from '@recurly/recurly-js';
import { TSubscriptionPlan } from '../types/type';

const DEFAULT_SUBS_PLAN_PRICES: TPlanTypeModel = {
  gpsStarter: {
    monthly: {
      shippingRate: 0,
      freeShippingQty: 5,
      costPerDevice: 12,
      oneTimeDeviceFee: 69,
      planCode: 'tracker_starter_monthly_01',
    },
    annual: {
      shippingRate: 0,
      freeShippingQty: 5,
      costPerDevice: 120,
      oneTimeDeviceFee: 69,
      planCode: 'tracker_starter_monthly_01',
    },
  },
  gpsPremium: {
    monthly: {
      shippingRate: 0,
      freeShippingQty: 5,
      costPerDevice: 20,
      oneTimeDeviceFee: 69,
      planCode: 'tracker_premium_monthly_01',
    },
    annual: {
      shippingRate: 0,
      freeShippingQty: 5,
      costPerDevice: 216,
      oneTimeDeviceFee: 69,
      planCode: 'tracker_premium_annual_01',
    },
  },
  trakViewPro: {
    monthly: {
      shippingRate: 0,
      freeShippingQty: 5,
      costPerDevice: 45,
      oneTimeDeviceFee: 129,
      planCode: 'dashcam_premium_monthly_01',
    },
    annual: {
      shippingRate: 0,
      freeShippingQty: 5,
      costPerDevice: 480,
      oneTimeDeviceFee: 129,
      planCode: 'dashcam_premium_annual_01',
    },
  },
};

const INITIAL_CABLE_ITEM: ICableCartItem = {
  productCode: '',
  vehicleMake: '',
  quantity: 1,
  maxInput: 1,
};

const DEFAULT_TRIAL_DAYS = 14;
export interface IOrderStateModel {
  email: string;
  phone: string;
  howDidYouFindUs?: string;
  howDidYouFindUsComment?: string;
  shippingAddress?: IAddressDetails;
  recurlyToken: string;
  cardBrand: string;
  cardLastNumbers: string;
  sameBillingAndShippingAddress: boolean;
  billingAddress?: IAddressDetails;
  devicesCount: number;
  billingPeriod: TBillingPeriod;
  isOrderCompleted: boolean;
  subscriptionPlanPrice: TPlanTypeModel;
  promoCodeKey: string;
  promoCodeData: TPromoCodeModel | null;
  firstBillingDate: Date;
  taxesRate: number;
  loadTaxesFailed: boolean;
  defaultTrialDays: number;
  errorTransactionMsg?: string;
  isValidShippingDetails: boolean;
  isValidBillingAddress: boolean;
  isCardDetailsValid: boolean;
  isStatusPending: boolean;
  vendorFulfillmentCode?: string;
  vendorCodeData?: IVendorCodeData;
  vendorCodeError: boolean;
  planDataLoaded: boolean;
  redirect: string | undefined;
  cableCartItems: ICableCartItem[];
  haveBigTrucks: boolean;
  planType: TPlanType;
  isReview: boolean;
}

const INITIAL_STATE: IOrderStateModel = {
  phone: '',
  email: '',
  devicesCount: 1,
  billingPeriod: 'monthly',
  promoCodeKey: '',
  isOrderCompleted: false,
  subscriptionPlanPrice: DEFAULT_SUBS_PLAN_PRICES,
  promoCodeData: null,
  firstBillingDate: new Date(),
  taxesRate: 0,
  loadTaxesFailed: false,
  defaultTrialDays: DEFAULT_TRIAL_DAYS,
  errorTransactionMsg: undefined,
  sameBillingAndShippingAddress: true,
  isValidBillingAddress: false,
  isValidShippingDetails: false,
  isCardDetailsValid: false,
  isStatusPending: false,
  shippingAddress: undefined,
  billingAddress: undefined,
  recurlyToken: '',
  cardBrand: '',
  cardLastNumbers: '',
  vendorFulfillmentCode: undefined,
  vendorCodeData: undefined,
  vendorCodeError: false,
  planDataLoaded: false,
  redirect: undefined,
  cableCartItems: [{ ...INITIAL_CABLE_ITEM }],
  haveBigTrucks: false,
  planType: 'gpsPremium',
  isReview: false,
};

@Injectable({
  providedIn: 'root',
})
export class OrderStateService {
  private readonly state$!: BehaviorSubject<IOrderStateModel>;
  public recurlyToken$: Subject<TokenPayload> = new Subject<TokenPayload>();

  private loadingState = {
    plan: false,
    promo: false,
    subTotalPrice: false,
    taxes: false,
  };

  private validVendorCouponCodes: Set<string> = new Set<string>(['ma20']);

  public heavyDutyCablesByMake$: BehaviorSubject<IHeavyDutyCablesByMake> = new BehaviorSubject({});

  constructor(private priceLoader: PriceLoader, private recurlyService: RecurlyService) {
    this.state$ = new BehaviorSubject(INITIAL_STATE);

    this.setPlanType();
    this._loadPricePlanMap();
    this._initVendorCodeData();

    this._initUpdateFirstBillingDate();
    this._initLoadingTrialPeriod();

    this._initLoadPromoCodeData();
    this._initLoadTaxesRate();

    this._updateCableQuantityMaxInputs();
  }

  public resetState(): void {
    this.updateState(INITIAL_STATE);
  }

  private _initLoadingTrialPeriod(): void {
    this.state$
      .pipe(
        map((state) => state.billingPeriod),
        distinctUntilChanged(),
        switchMap((period) => {
          return this.recurlyService.loadPlan(this.subscriptionPlanCode()).pipe(
            map((plan) => (plan.trial && plan.trial.interval === 'days' ? plan.trial.length : 0)),
            catchError(() => of(null)),
          );
        }),
        filter((notNull) => notNull !== null),
      )
      .subscribe((trialDays) => {
        this._updateState({
          defaultTrialDays: trialDays as number,
        });
      });
  }

  private _initUpdateFirstBillingDate(): void {
    this.state$
      .pipe(
        distinctUntilChanged(
          ({ defaultTrialDays: xa, promoCodeData: xb }, { defaultTrialDays: ya, promoCodeData: yb }) =>
            xa === ya && xb === yb,
        ),
      )
      .subscribe(({ defaultTrialDays, promoCodeData }) => {
        const firstBillingDate = new Date();

        if (promoCodeData?.type === 'free') {
          switch (promoCodeData?.trialUnit) {
            case 'month':
              firstBillingDate.setMonth(firstBillingDate.getMonth() + promoCodeData.trialLength);
              break;
            case 'day':
              firstBillingDate.setDate(firstBillingDate.getDate() + promoCodeData.trialLength);
              break;
          }
        } else {
          firstBillingDate.setDate(firstBillingDate.getDate() + defaultTrialDays);
        }

        this._updateState({ firstBillingDate });
      });
  }

  private _initLoadPromoCodeData(): void {
    this.state$
      .pipe(
        distinctUntilChanged(
          ({ billingPeriod: xa, promoCodeKey: xb }, { billingPeriod: ya, promoCodeKey: yb }) => xa === ya && xb === yb,
        ),
        filter(({ billingPeriod, promoCodeKey }) => !!billingPeriod && !!promoCodeKey),
        tap(() => {
          this.loadingState.promo = true;
          this._updateState({ promoCodeData: null });
        }),
        switchMap(({ billingPeriod, promoCodeKey }) =>
          this.priceLoader.loadPromoCode(billingPeriod, promoCodeKey as string, this.subscriptionPlanCode()).pipe(
            catchError(() => of(null)),
            finalize(() => (this.loadingState.promo = false)),
          ),
        ),
      )
      .subscribe((promoCodeData) => this._updateState({ promoCodeData }));
  }

  private _initLoadTaxesRate(): void {
    this.state$
      .pipe(
        switchMap((state) =>
          state.sameBillingAndShippingAddress ? of(state.shippingAddress) : of(state.billingAddress),
        ),
        distinctUntilChanged((prev, next) => {
          return (!prev?.postalCode && !next?.postalCode) || prev?.postalCode === next?.postalCode;
        }),
        debounceTime(500),
        switchMap((address) => {
          if (!address || !address.postalCode || !address.country) {
            return of({
              taxesRate: 0,
              loadTaxesFailed: false,
            });
          }
          this.loadingState.taxes = true;
          return this.recurlyService.loadTaxesRate(address?.postalCode as string, address?.country as string).pipe(
            finalize(() => (this.loadingState.taxes = false)),
            map<ITaxesRate | null, Partial<IOrderStateModel>>((taxesRate) => ({
              taxesRate: (taxesRate && taxesRate.rate) || 0,
              loadTaxesFailed: false,
            })),
            catchError(() =>
              of<Partial<IOrderStateModel>>({
                taxesRate: 0,
                loadTaxesFailed: true,
              }),
            ),
          );
        }),
      )
      .subscribe((taxesUp) => this._updateState(taxesUp));
  }

  private _initVendorCodeData(): void {
    this.state$
      .pipe(
        distinctUntilChanged(({ vendorFulfillmentCode: vfca }, { vendorFulfillmentCode: vfcb }) => vfca === vfcb),
        filter(({ vendorFulfillmentCode, vendorCodeData }) => !!vendorFulfillmentCode && !!vendorCodeData),
        map(({ vendorCodeData }) => vendorCodeData),
      )
      .subscribe((data) => {
        if (!data) {
          return;
        }

        const { numberOfTrackers, numberOfDashcams, vendorPlans } = data;

        if (numberOfTrackers > 0 && numberOfDashcams > 0) {
          this.updateState({
            planType: 'hybrid',
            subscriptionPlanPrice: vendorPlans,
          });
        } else if (numberOfTrackers > 0) {
          this.updateState({
            devicesCount: numberOfTrackers,
            planType: 'gpsPremium',
            subscriptionPlanPrice: vendorPlans,
          });
        } else if (numberOfDashcams > 0) {
          this.updateState({
            devicesCount: numberOfDashcams,
            planType: 'trakViewPro',
            subscriptionPlanPrice: vendorPlans,
          });
        }

        if (data.couponCode) {
          this.applyPromoCode(data.couponCode);
        }
      });
  }

  private _updateState(updates: Partial<IOrderStateModel>): void {
    this.state$.next({
      ...this.state$.value,
      ...updates,
    });
  }

  public updateState(updates: Partial<IOrderStateModel>): void {
    this._updateState(updates);
  }

  public state(): Observable<IOrderStateModel> {
    return this.state$;
  }

  public stateValue(): IOrderStateModel {
    return this.state$.getValue();
  }

  public setShippingAddress(shippingAddress: IAddressDetails, isValidShippingDetails: boolean): void {
    this._updateState({ shippingAddress, isValidShippingDetails });
  }

  public setBillingAddress(billingAddress: IAddressDetails | undefined, isValidBillingAddress: boolean): void {
    this._updateState({ billingAddress, isValidBillingAddress });
  }

  public setBillingPeriod(billingPeriod: TBillingPeriod): void {
    this._updateState({
      billingPeriod,
    });
    if (this.promoCodeData()) {
      this.applyPromoCode((this.promoCodeData() as TPromoCodeModel).couponCode);
    }
  }

  public setDevicesCount(devicesCount: number): void {
    this._updateState({
      devicesCount,
    });
  }

  public setIsCardDetailsValid(isCardDetailsValid: boolean): void {
    this._updateState({
      isCardDetailsValid,
    });
  }

  public setCardBrand(cardBrand: string): void {
    this._updateState({
      cardBrand,
    });
  }

  public setCardLastNumbers(cardLastNumbers: string): void {
    this._updateState({
      cardLastNumbers,
    });
  }

  public setCommonDetails(details: {
    email: string;
    phone: string;
    howDidYouFindUs: string;
    howDidYouFindUsComment: string;
  }): void {
    this._updateState({
      ...details,
    });
  }

  public setErrorTransactionMsg(errorTransactionMsg: string): void {
    this._updateState({ errorTransactionMsg });
  }

  public setPlanType(planType?: TPlanType): void {
    if (!planType) {
      const planPath: TPlanTypePath = new URL(window.location.href).pathname.replace('/', '') as TPlanTypePath;
      planType = 'gpsPremium';

      if (planPath === 'trakview') {
        planType = 'trakViewPro';
      }
      if (planPath === 'starter') {
        planType = 'gpsStarter';
      }
      if (planPath === 'premium') {
        planType = 'gpsPremium';
      }
    }

    this._updateState({
      planType,
    });
  }

  public markOrderAsCompleted(): void {
    this._updateState({
      isOrderCompleted: true,
    });
  }

  public markVendorCodeError(): void {
    this._updateState({ vendorCodeError: true });
  }

  public isOrderCompleted(): boolean {
    return this.state$.value.isOrderCompleted;
  }

  public toggleReview(): void {
    this.updateState({ isReview: !this.stateValue().isReview });
  }

  public transactionError(): string | undefined {
    return this.state$.value.errorTransactionMsg;
  }

  public firstBillingDate(): Date {
    return this.state$.value.firstBillingDate;
  }

  public firstBillingDateVWO(): Date {
    const firstBillingDate = new Date();
    firstBillingDate.setDate(firstBillingDate.getDate() + this.state$.value.defaultTrialDays);
    return firstBillingDate;
  }

  public isLoading(): boolean {
    return (
      this.loadingState.plan || this.loadingState.subTotalPrice || this.loadingState.promo || this.loadingState.taxes
    );
  }

  public isOrderDetailsValid(): boolean {
    const state = this.stateValue();
    return (
      !state.isStatusPending &&
      state.isValidShippingDetails &&
      (state.sameBillingAndShippingAddress || state.isValidBillingAddress) &&
      state.isCardDetailsValid &&
      !this.cableLimitError &&
      !state.vendorCodeError
    );
  }

  public applyPromoCode(promoCodeKey: string): void {
    this._updateState({
      promoCodeKey,
    });
  }

  public billingPeriod(): TBillingPeriod {
    return this.state$.value.billingPeriod;
  }

  public billingPeriodChanges(): Observable<TBillingPeriod> {
    return this.state$.pipe(
      map(({ billingPeriod }) => billingPeriod),
      distinctUntilChanged(),
    );
  }

  public devicesCount(): number {
    return this.state$.value.devicesCount;
  }

  public oneVehicleTrackingPrice(planType?: TForcePlanType): number {
    planType = planType ?? (this.planType as TForcePlanType);
    return this.state$.value.subscriptionPlanPrice[planType][this.billingPeriod()].costPerDevice;
  }

  public oneDevicePrice(planType?: TForcePlanType): number {
    planType = planType ?? (this.planType as TForcePlanType);
    return this.state$.value.subscriptionPlanPrice[planType][this.billingPeriod()].oneTimeDeviceFee;
  }

  public totalVehiclesTrackingPrice(planType?: TForcePlanType): number {
    planType = planType ?? (this.planType as TForcePlanType);
    return this.state$.value.devicesCount * this.oneVehicleTrackingPrice(planType);
  }

  public totalVendorTrackerSubscriptionPrice(): number {
    return this.getNumberOfVendorTrackers() * this.oneVehicleTrackingPrice('gpsPremium');
  }

  public totalVendorDashcamSubscriptionPrice(): number {
    return this.getNumberOfVendorDashcams() * this.oneVehicleTrackingPrice('trakViewPro');
  }

  public totalVehiclesTrackingPriceWithDiscount(): number {
    if (this.planType === 'hybrid') {
      return this.totalVendorTrackerSubscriptionPrice() + this.totalVendorDashcamSubscriptionPrice();
    }
    return this.totalVehiclesTrackingPrice(this.planType);
  }

  public hasVehicleTrackingDiscount(): boolean {
    return false;
  }

  public totalDevicesPriceWithDiscount(): number {
    if (this.planType === 'hybrid') {
      return (
        this.getNumberOfVendorDashcams() * this.oneDevicePrice('trakViewPro') +
        this.getNumberOfVendorTrackers() * this.oneDevicePrice('gpsPremium')
      );
    }
    return this.devicesCount() * this.oneDevicePrice();
  }

  // TODO remove or change
  public hasDevicesDiscount(): boolean {
    return true;
  }

  public subTotalPrice(): number {
    return this.totalVehiclesTrackingPriceWithDiscount() + this.totalDevicesPriceWithDiscount();
  }

  public discountAmount(): number {
    switch (this.promoCodeData()?.type) {
      case 'free':
        const promoCodeData = this.promoCodeData() as IPromoCodeTrialFree;
        if (promoCodeData.trialUnit === 'month') {
          return (
            this.state$.value.subscriptionPlanPrice[this.planType as TForcePlanType].monthly.costPerDevice *
            promoCodeData.trialLength
          );
        }
        return 0;
      case 'fixed':
        return (this.promoCodeData() as IPromoCodeFixedDiscount).fixedAmount;
      case 'percentage':
        return this.subTotalPrice() * ((this.promoCodeData() as IPromoCodePercentageDiscount).discountPercentage / 100);
      default:
        return 0;
    }
  }

  public subTotalPriceWithDiscount(): number {
    const subTotalWithDiscount = this.subTotalPrice() - this.discountAmount();
    return subTotalWithDiscount > 0 ? subTotalWithDiscount : 0;
  }

  public shippingPrice(): number {
    return 5;
    // if (this.state$.value.subscriptionPlanPrice[this.billingPeriod()].freeShippingQty < this.devicesCount()) {
    //   return 0;
    // }

    // return this.state$.value.subscriptionPlanPrice[this.billingPeriod()].shippingRate;
  }

  public shippingTotalPrice(): number {
    return 0;
    // return this.shippingPrice();
  }

  public taxesPrice(): number {
    return this.subTotalPriceWithDiscount() * this.state$.value.taxesRate;
  }

  public subTotalPriceWithTaxes(): number {
    return this.subTotalPriceWithDiscount() + this.taxesPrice();
  }

  public promoCodeData(): TPromoCodeModel | null {
    return this.state$.value.promoCodeData;
  }

  public getShippingAddress(): IAddressDetails | undefined {
    const state = this.stateValue();
    return state.shippingAddress;
  }

  public getBillingAddress(): IAddressDetails | undefined {
    const state = this.stateValue();
    return state.sameBillingAndShippingAddress ? state.shippingAddress : state.billingAddress;
  }

  // TODO remove?
  public trialDaysCount(): number {
    const state = this.stateValue();
    if (state.promoCodeData?.type === 'free') {
      return state.promoCodeData?.trialLength;
    }

    return this.stateValue().defaultTrialDays;
  }

  public getVendorSpecificDiscountLabel(): string {
    const isValidVendorCode =
      !!this.state$.value.promoCodeData &&
      this.validVendorCouponCodes.has(this.state$.value.promoCodeData.couponCode.toLowerCase());
    const labelSuffix = isValidVendorCode
      ? ` - ${(this.promoCodeData() as IPromoCodePercentageDiscount).discountPercentage}% off`
      : '';
    return `Special Discount${labelSuffix}`;
  }

  public getNumberOfVendorTrackers(): number {
    return this.state$.value.vendorCodeData?.numberOfTrackers || 0;
  }

  public getNumberOfVendorDashcams(): number {
    return this.state$.value.vendorCodeData?.numberOfDashcams || 0;
  }

  public getDiscount(): number {
    return this.subTotalPrice() - this.subTotalPriceWithDiscount();
  }

  public getRedirectAddress(): string {
    return this.state$.value.redirect || '';
  }

  private _loadPricePlanMap(): void {
    this.loadingState.plan = true;
    this.priceLoader
      .loadSubsPlanPriceMap()
      .pipe(finalize(() => (this.loadingState.plan = false)))
      .subscribe(([subscriptionPlanPrice, heavyDutyCablesByMake]) => {
        this._updateState({ subscriptionPlanPrice, planDataLoaded: true });
        this.heavyDutyCablesByMake$.next(heavyDutyCablesByMake);
      });
  }

  public getProductsList(): IProductModel[] {
    if (this.devicesCount() < 1) {
      return [];
    }

    const cableProducts = this.haveBigTrucks
      ? [...this.cableCartItems]
          .map(({ productCode, quantity }) => ({ productCode, quantity }))
          .filter(({ productCode }) => productCode)
      : [];

    if (this.isHybridVendorPlan) {
      return [
        {
          productCode: this.subscriptionPlanCode('gpsPremium'),
          quantity: this.getNumberOfVendorTrackers(),
        },
        {
          productCode: this.subscriptionPlanCode('trakViewPro'),
          quantity: this.getNumberOfVendorDashcams(),
        },
      ];
    }

    return [
      {
        productCode: this.subscriptionPlanCode(),
        quantity: this.devicesCount(),
      } as IProductModel,
      ...cableProducts,
    ];
  }

  public getEmail(): string {
    return this.state$.value.email || '';
  }

  public getPhone(): string {
    return this.state$.value.phone || '';
  }

  public subscriptionPlanCode(planType?: TForcePlanType): TSubscriptionPlan {
    planType = planType ?? ((this.planType === 'hybrid' ? 'gpsPremium' : this.planType) as TForcePlanType);
    return this.state$.value.subscriptionPlanPrice[planType][this.billingPeriod()].planCode;
  }

  public get cardWithLastFourDigits(): string {
    return `${this.state$.value.cardBrand?.toUpperCase()} ending with ${this.state$.value.cardLastNumbers}`;
  }

  public get planType(): TPlanType {
    return this.state$.value.planType;
  }

  public get isVendorSpecific(): boolean {
    return (this.state$.value.vendorCodeData?.numberOfDevices || 0) > 0;
  }

  public get isHybridVendorPlan(): boolean {
    return this.state$.value.planType === 'hybrid';
  }

  // ############ HD Cable Cart ############

  public getHeavyDutyCableItems(): IHeavyDutyCablesByMake {
    return this.heavyDutyCablesByMake$.value;
  }

  public getHeavyDutyCable(productCode: string): IHeavyDutyCable {
    const heavyDutyCablesByMake = this.heavyDutyCablesByMake$.value;
    return heavyDutyCablesByMake?.[productCode] || heavyDutyCablesByMake?.['not listed'];
  }

  public setCableCartItems(cartItems: ICableCartItem[]): void {
    this._updateState({ cableCartItems: cartItems });
  }

  public resetCableCart(): void {
    this._updateState({ cableCartItems: [{ ...INITIAL_CABLE_ITEM }] });
  }

  public hdVehicleDropdown(): Observable<IDropdownItem<string>[]> {
    return this.heavyDutyCablesByMake$.pipe(
      distinctUntilChanged(),
      map((heavyDutyCablesByMake: IHeavyDutyCablesByMake) => {
        return Object.keys(heavyDutyCablesByMake).reduce((res: IDropdownItem<string>[], productCode: string) => {
          res.push({ name: uppercaseFirstChars(productCode), value: productCode });
          return res;
        }, []);
      }),
      filter((dropdownList) => dropdownList.length > 0),
    );
  }

  public onCartItemChange(): Observable<{ devicesCount: number; cableCartItems: ICableCartItem[] }> {
    return this.state$.pipe(
      throttleTime(500),
      distinctUntilChanged(
        ({ devicesCount: xa, cableCartItems: xb }, { devicesCount: ya, cableCartItems: yb }) => xa === ya && xb === yb,
      ),
      map(({ devicesCount, cableCartItems }) => ({ devicesCount, cableCartItems })),
    );
  }

  private _updateCableQuantityMaxInputs(): void {
    this.onCartItemChange()
      .pipe(
        map(({ devicesCount, cableCartItems }) => {
          const updatedCart = [...cableCartItems];
          updatedCart.forEach((item) => {
            devicesCount = this.isHybridVendorPlan ? this.getNumberOfVendorTrackers() : devicesCount;
            let maxInput = devicesCount - this.cablesCount + item.quantity;
            maxInput = maxInput > 1 ? maxInput : 1;
            item.maxInput = maxInput;
          });
          return updatedCart;
        }),
      )
      .subscribe((updatedCart) => this._updateState({ cableCartItems: updatedCart }));
  }

  public get haveBigTrucks(): boolean {
    return this.state$.value.haveBigTrucks;
  }

  public get cableCartItems(): ICableCartItem[] {
    return this.state$.value.cableCartItems;
  }

  public get cableInCart(): boolean {
    return this.cablesCount > 0 && this.cableCartItems[0].productCode !== '';
  }

  public get cableLimitReached(): boolean {
    return this.cablesCount >= this.maxCountForCables();
  }

  public get cableLimitExceeded(): boolean {
    return this.cablesCount > this.maxCountForCables();
  }

  public get cableLimitError(): boolean {
    return this.haveBigTrucks && this.cableLimitExceeded;
  }

  public get lastCableItemNotSelected(): boolean {
    if (this.cableCartEmpty) {
      return false;
    }
    return !this.cableCartItems[this.cableCartItems.length - 1].vehicleMake;
  }

  public get cableCartEmpty(): boolean {
    return !this.cableCartItems.length;
  }

  private get cablesCount(): number {
    return this.cableCartItems.filter((item) => item.productCode).reduce((res, item) => item.quantity + res, 0);
  }

  public toggleHaveBigTrucks(): void {
    this._updateState({ haveBigTrucks: !this.haveBigTrucks });
  }

  public addCableItem(): void {
    let maxInput = this.maxCountForCables() - this.cablesCount;
    maxInput = maxInput > 1 ? maxInput : 1;
    const updatedCart = [...this.cableCartItems, { ...INITIAL_CABLE_ITEM, maxInput }];
    this._updateState({ cableCartItems: updatedCart });
  }

  public removeCableItem(itemIndex: number): void {
    const updatedCart = [...this.cableCartItems];
    updatedCart.splice(itemIndex, 1);
    this._updateState({ cableCartItems: updatedCart });
  }

  public setCableproductCode(event: Event, index: number): void {
    const vehicleName = (event.target as HTMLInputElement).value;
    const updatedCart = [...this.cableCartItems];
    updatedCart[index] = { ...updatedCart[index], productCode: this.getHeavyDutyCable(vehicleName).productCode };
    this._updateState({ cableCartItems: updatedCart });
  }

  public onCableQuantityChanged(count: number | string, index: number): void {
    const updatedCart = [...this.cableCartItems];
    updatedCart[index] = { ...updatedCart[index], quantity: Number(count) };
    this._updateState({ cableCartItems: updatedCart });
  }

  private maxCountForCables(): number {
    return this.isHybridVendorPlan ? this.getNumberOfVendorTrackers() : this.devicesCount();
  }
}
