import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalService } from 'src/app/core/service/modal-state.service';

@Component({
  selector: 'app-jobber-connect-modal',
  templateUrl: './jobber-connect-modal.component.html',
  styleUrls: ['./jobber-connect-modal.component.scss'],
})
export class JobberConnectModalComponent {
  @Input()
  public vendorOAuthUrl = '';

  @Output()
  private nextActionClicked = new EventEmitter();

  constructor(private modalService: ModalService) {}

  public onNextClicked(): void {
    const redirectWindow = window.open(this.vendorOAuthUrl, '_blank');
    if (redirectWindow?.location) {
      this.modalService.closeModal('jobber-connect-modal');
      this.nextActionClicked.emit();
    }
  }
}
