import { IProductModel } from './ibase-price.model';

export type TBillingPeriod = 'monthly' | 'annual';

export type TPlanType = 'pro' | 'premium' | 'starter' | 'hybrid';

export type TForcePlanType = Extract<TPlanType, 'pro' | 'premium' | 'starter'>;

export type TPlanTypePath = 'trakview' | 'premium' | 'starter';

export interface IPaymentDetails {
  recurlyToken: string;
  useShippingAddress: boolean;
  address: IAddressDetails;
}

export interface IAddressDetails {
  email: string;
  firstName: string;
  lastName: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
  companyName: string;
  phone: string;
  country: 'US';
}

export interface IHeavyDutyCablesByMake {
  [make: string]: IHeavyDutyCable;
}

export interface IHeavyDutyCable {
  productCode: string;
  oneTimeFee: number;
}

export interface ICableCartItem extends IProductModel {
  vehicleMake: string;
  maxInput: number;
}

export interface IHeavyDutyCableInfo {
  name: string;
  port: string;
  desc: string;
  imgSrc: string;
}

export const HD_CABLE_INFO_MAP: { [productCode: string]: IHeavyDutyCableInfo } = {
  'HDC-001': {
    name: 'J-Bus',
    port: 'J-1939',
    desc: 'connects to J-1939 ports',
    imgSrc: 'J-Bus-cable.png',
  },
  'HDC-002': {
    name: '16-pin OBD',
    port: '16-pin',
    desc: 'connects to 16-pin OBD ports',
    imgSrc: '16-pin-cable.png',
  },
  'HDC-003': {
    name: 'RP-1226',
    port: 'RP-1226',
    desc: 'either RP-1226 or J-1939 ports',
    imgSrc: 'RP-1226-cable.png',
  },
};
