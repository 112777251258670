import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TSubscriptionPlan } from '../types/type';
import { IHeavyDutyCablesByMake } from '../models/iorder-state.model';

import { AbstractEndpoints } from './abstract.endpoints';

const FULFILLMENT_URL = '{apiUrl}/fleets/fulfillment';
const PROMOTIONS_URL = `${FULFILLMENT_URL}/promotions`;

export interface IFulfillmentPlanDto {
  flatShippingRate: number;
  freeShippingQuantityRequirement: number;
  costPerDevice: number;
  oneTimeDeviceFee: number;
  itemCategory: string;
  planCode: string;
}

export interface IFulfillmentResponseDto {
  plansByCode: {
    [planCode in TSubscriptionPlan]: IFulfillmentPlanDto;
  };
  heavyDutyCablesByMake: IHeavyDutyCablesByMake;
}

export interface IPromotionsResponseDto {
  promotionCode: string;
  hardwareDiscountPercentage: number;
  quantityAvailablePerCustomer: number;
}

@Injectable({ providedIn: 'root' })
export class FulfillmentEndpoints extends AbstractEndpoints {
  constructor(private httpClient: HttpClient) {
    super();
  }

  public getFulfillment(): Observable<IFulfillmentResponseDto> {
    return this.httpClient.get<IFulfillmentResponseDto>(this.createUrl(FULFILLMENT_URL), {
      params: {
        clientId: this.getClientId(),
      },
    });
  }

  public getPromotions(planCode: TSubscriptionPlan, promotionCode: string): Observable<IPromotionsResponseDto> {
    return this.httpClient.get<IPromotionsResponseDto>(this.createUrl(PROMOTIONS_URL), {
      params: {
        clientId: this.getClientId(),
        promotionCode,
        planCode,
      },
    });
  }
}
