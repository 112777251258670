import { DOCUMENT } from '@angular/common';
import { EventEmitter, Inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export type TModalType =
  | 'hdc-info-modal'
  | 'confirm-close'
  | 'order-confirmed-modal'
  | 'popup-message-modal'
  | 'jobber-connect-modal'
  | '';

@Injectable({
  providedIn: 'root',
})
export class ModalService implements OnDestroy {
  private readonly onDestroy$: Subject<void> = new Subject();
  private modalStack: TModalType[] = [];
  private modalStackSubject = new BehaviorSubject<TModalType[]>([]);

  public modalClosed = new EventEmitter<TModalType>();
  public modalStack$ = this.modalStackSubject.asObservable();

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.modalStack$.pipe(takeUntil(this.onDestroy$)).subscribe((stack) => {
      if (stack.length) {
        this.document.body.style.overflow = 'hidden';
      } else {
        this.document.body.style.overflow = 'auto';
      }
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public openModal(modalId: TModalType): void {
    this.modalStack.push(modalId);
    this.modalStackSubject.next(this.modalStack);
  }

  public closeModal(modalId: TModalType): void {
    this.modalStack = this.modalStack.filter((id) => modalId !== id);
    this.modalStackSubject.next(this.modalStack);
    this.modalClosed.emit(modalId);
  }

  public isModalOpen(modalId: TModalType): boolean {
    return modalId === this.getLastModal();
  }

  private getLastModal(): TModalType {
    if (this.modalStack.length > 0) {
      return this.modalStack[this.modalStack.length - 1];
    }
    return '';
  }
}
