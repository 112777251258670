<app-common-layout>
  <div *ngIf="isLoading || (emailTabSubj$ | async) === true || (phoneTabSubj$ | async) === true" class="blackout">
    <div class="spinner"></div>
  </div>

  <div *ngIf="!isHybridVendorPlan" class="mobile-header-wrap">
    <img [src]="planDeviceImg" class="device-img" alt="device" />
    <h2 class="section-header">{{ planTypeName }} Plan</h2>
  </div>

  <div class="container order-container">
    <app-shipping-details
      [planType]="planType"
      [isReview]="isReview"
      class="content customer-details md-hide"
    ></app-shipping-details>

    <app-order-payment
      [reCaptchaFailed]="reCaptchaFailed"
      [counter]="counter$ | async"
      (toggleReview)="toggleReview()"
      class="content order-details"
    ></app-order-payment>
  </div>

  <app-modal modalId="popup-message-modal" [isCloseable]="false">
    <app-popup-message-modal></app-popup-message-modal>
  </app-modal>

  <app-modal modalId="jobber-connect-modal" [isCloseable]="false">
    <app-jobber-connect-modal
      (nextActionClicked)="redirectOnOrderPlaced()"
      [vendorOAuthUrl]="this.orderState.vendorOAuthUrl"
    ></app-jobber-connect-modal>
  </app-modal>
</app-common-layout>
