import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

type TOperators = 'add' | 'minus';
const MAX_ORDERS = 50;
const Operations: { [key in TOperators]: (n: number, max: number, min: number) => number } = {
  add: (n: number, max: number, _) => (n < max ? n + 1 : n),
  minus: (n: number, _, min: number) => (n > min ? n - 1 : n),
};

@Component({
  selector: 'app-input-quantity',
  templateUrl: './input-quantity.component.html',
  styleUrls: ['./input-quantity.component.scss'],
})
export class InputQuantityComponent {
  @ViewChild('inputEl', { static: true })
  private inputEl!: ElementRef<HTMLInputElement>;

  @Input()
  public max = MAX_ORDERS;
  @Input()
  public min = 1;
  @Input()
  public disabled = false;
  @Input()
  public quantity = 1;
  @Input()
  public inputInvalid = false;

  @Output()
  public valueChange = new EventEmitter<number>();

  public onInputValChanged(event: Event): void {
    if (this.disabled) {
      return;
    }
    const inputVal = Number((event.target as HTMLInputElement)?.value || this.min);
    this.inputEl.nativeElement.value = inputVal.toString();
    this.valueChange.emit(inputVal);
  }

  public onOperatorClicked(operator: TOperators): void {
    if (this.disabled) {
      return;
    }
    const operation = Operations[operator];
    this.valueChange.emit(operation(this.quantity, this.max, this.min));
  }

  public operatorDisabled(operator: TOperators): boolean {
    return this.disabled || (operator === 'add' ? this.quantity >= this.max : this.quantity <= this.min);
  }
}
