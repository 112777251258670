import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import { Router } from '@angular/router';
import { IAddressDetails } from 'src/app/core/models/iorder-state.model';
import { OrderStateService } from 'src/app/core/service/order-state.service';
import { RecurlyService } from 'src/app/shared/components/recurly/services/recurly.service';
import { googleMapsStyles } from 'src/app/shared/constants/googlemap.styles';
import { TPromoCodeModel } from 'src/app/core/models/ipromo-code.model';
import { planDevicePreviewImg, planTypeName } from 'src/app/utils/string';
import { ModalService } from 'src/app/core/service/modal-state.service';

@Component({
  templateUrl: './congratulation.component.html',
  styleUrls: ['./congratulation.component.scss'],
})
export class CongratulationComponent implements OnInit, AfterViewInit {
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;
  @ViewChild(MapMarker, { static: false }) mapMarker: MapMarker;

  public get billingAddress(): IAddressDetails | undefined {
    return this.orderState.getBillingAddress();
  }

  public get shippingAddress(): IAddressDetails | undefined {
    return this.orderState.getShippingAddress();
  }

  public get promoCode(): TPromoCodeModel | null {
    return this.orderState.promoCodeData();
  }

  public get planTypeName(): string {
    return planTypeName(this.orderState.planType);
  }

  public get planDevicePreviewImg(): string {
    return planDevicePreviewImg(this.orderState.planType);
  }

  public get isVendorSpecific(): boolean {
    return this.orderState.isVendorSpecific;
  }

  public get isHybridVendorPlan(): boolean {
    return this.orderState.isHybridVendorPlan;
  }

  public readonly mapOptions: google.maps.MapOptions = {
    zoom: 14,
    disableDefaultUI: true,
    styles: googleMapsStyles,
  };

  public readonly markerOptions: google.maps.MarkerOptions = {
    draggable: false,
    icon: '/assets/icons/map-marker.png',
  };

  public center: google.maps.LatLngLiteral = {
    lat: 0,
    lng: 0,
  };

  public markerText = '';

  public displayMap = true;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public orderState: OrderStateService,
    public recurlyService: RecurlyService,
    private modalService: ModalService,
    private router: Router,
  ) {}

  public ngOnInit(): void {
    if (!this.orderState.isOrderCompleted()) {
      this.router.navigateByUrl('/');
    }

    this.modalService.openModal('order-confirmed-modal');
    this.setMarkerCoordsAndText();
  }

  public ngAfterViewInit(): void {
    this.openInfoWindow(this.mapMarker);
  }

  private openInfoWindow(marker: MapMarker): void {
    this.infoWindow.open(marker);
  }

  private getFullAddress(shippingAddress?: IAddressDetails): string {
    const streetAddress = shippingAddress?.address1;
    const state = shippingAddress?.state;
    const city = shippingAddress?.city;
    const zip = shippingAddress?.postalCode;
    return `${streetAddress}, ${city}, ${state} ${zip}, United States`;
  }

  private setMarkerCoordsAndText(): void {
    const address = this.getFullAddress(this.shippingAddress);
    const geocoder = new google.maps.Geocoder();

    this.markerText = `${this.shippingAddress?.city}, ${this.shippingAddress?.state}`;

    geocoder.geocode({ address }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        this.center = {
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        };
      } else {
        this.displayMap = false;
      }
    });
  }
}
