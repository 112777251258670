import { Component, Input, OnChanges } from '@angular/core';
import { IAddressDetails, TPlanType } from '../../../../../../core/models/iorder-state.model';
import { OrderStateService } from '../../../../../../core/service/order-state.service';
import { planDevicePreviewImg } from 'src/app/utils/string';

@Component({
  selector: 'app-shipping-details',
  templateUrl: './shipping-details.component.html',
  styleUrls: ['./shipping-details.component.scss'],
})
export class ShippingDetailsComponent implements OnChanges {
  @Input()
  public planType: TPlanType;
  @Input()
  public isReview = false;

  public shippingDetails: IAddressDetails | undefined;
  public billingDetails: IAddressDetails | undefined;
  public paymentDetails: unknown;

  constructor(public orderStateService: OrderStateService) {}

  public get planDevicePreviewImg(): string {
    return planDevicePreviewImg(this.planType);
  }

  public ngOnChanges(): void {
    if (this.isReview) {
      this.paymentDetails = this.orderStateService.cardWithLastFourDigits;
      this.shippingDetails = this.orderStateService.getShippingAddress();
      this.billingDetails = this.orderStateService.getBillingAddress();
    }
  }
}
