<div class="input-quantity">
  <svg-icon
    src="/assets/icons/minus-circled.svg"
    (click)="onOperatorClicked('minus')"
    [class.disabled]="operatorDisabled('minus')"
  ></svg-icon>
  <input
    [value]="quantity"
    (change)="onInputValChanged($event)"
    type="number"
    [min]="min"
    [max]="max"
    oninput="validity.valid||(value='');"
    [disabled]="disabled"
    [class.warning]="inputInvalid"
  />
  <svg-icon
    src="/assets/icons/add-circled.svg"
    (click)="onOperatorClicked('add')"
    [class.disabled]="operatorDisabled('add')"
  ></svg-icon>
</div>
