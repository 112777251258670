import { NgModule } from '@angular/core';
import { Routes, RouterModule, Route } from '@angular/router';
import { CongratulationComponent } from '../view/order/pages/congratulation/congratulation.component';
import { OrderSingleStepComponent } from '../view/order/pages/order-single-step/order-single-step.component';

// make sure that not found case set as last element of routes list
const notFoundCase: Route = {
  path: '**',
  redirectTo: '',
};

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: OrderSingleStepComponent,
      },
      {
        path: 'starter',
        component: OrderSingleStepComponent,
      },
      {
        path: 'premium',
        component: OrderSingleStepComponent,
      },
      {
        path: 'trakview', // old route (same as pro)
        component: OrderSingleStepComponent,
      },
      {
        path: 'pro',
        component: OrderSingleStepComponent,
      },
      {
        path: 'jobber/premium',
        component: OrderSingleStepComponent,
      },
      {
        path: 'jobber/pro',
        component: OrderSingleStepComponent,
      },
      {
        path: 'shipping/payment/congratulation',
        component: CongratulationComponent,
      },
      {
        path: 'shipping/payment/congratulation/singlevehicle',
        component: CongratulationComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot([...routes, notFoundCase], { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
