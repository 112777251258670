import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonLayoutModule } from 'src/app/shared/layouts/common-layout/common-layout.module';
import { RecurlyModule } from 'src/app/shared/components/recurly/recurly.module';
import { SelectModule } from 'src/app/shared/forms/select/select.module';
import { CongratulationComponent } from './pages/congratulation/congratulation.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HintModule } from '../../shared/components/hint/hint.module';
import { PriceModule } from 'src/app/shared/pipes/price/price.module';
import { FormErrorModule } from 'src/app/shared/forms/form-error/form-error.module';
import { AppInputModule } from 'src/app/shared/forms/input/input.module';
import { NgxMaskModule } from 'ngx-mask';
import { GoogleMapsModule } from '@angular/google-maps';
import { RouterModule } from '@angular/router';
import { AnnualModule } from 'src/app/shared/pipes/annual/annual.module';
import { BreakpointModule } from 'src/app/shared/breakpoint/breakpoint.module';
import { OrderSingleStepComponent } from './pages/order-single-step/order-single-step.component';
import { MarkTouchedIfLengthGreaterThanModule } from '../../shared/directives/mark-touched-if-length-greater-than/mark-touched-if-length-greater-than.module';
import { FaqTooltipComponent } from './components/faq-tooltip/faq-tooltip.component';
import { ModalModule } from 'src/app/shared/components/modal/modal.module';
import { HdcInfoModalComponent } from './components/hdc-info-modal/hdc-info-modal.component';
import { OrderPaymentComponent } from './pages/order-single-step/components/order-payment/order-payment.component';
import { InputPromoComponent } from './pages/order-single-step/components/order-payment/input-promo/input-promo.component';
import { CableCartComponent } from './pages/order-single-step/components/order-payment/cable-cart/cable-cart.component';
import { PaymentDetailsComponent } from './pages/order-single-step/components/payment-details/payment-details.component';
import { InputQuantityComponent } from 'src/app/shared/components/input-quantity/input-quantity.component';
import { ShippingDetailsComponent } from './pages/order-single-step/components/shipping-details/shipping-details.component';
import { AddressFormComponent } from './components/address-form/address-form.component';
import { PhonePipe } from '../../shared/pipes/phone/phone.pipe';
import { StateNamePipe } from '../../shared/pipes/state-name.pipe';
import { OrderConfirmedModalComponent } from './components/order-confirmed-modal/order-confirmed-modal.component';
import { PopupMessageModalComponent } from './components/popup-message-modal/popup-message-modal.component';
import { OrderPlanDetailsComponent } from './pages/order-single-step/components/order-payment/order-plan-details/order-plan-details.component';
import { OrderPlanSummaryComponent } from './pages/congratulation/order-plan-summary/order-plan-summary.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { JobberConnectModalComponent } from './components/jobber-connect-modal/jobber-connect-modal.component';

@NgModule({
  declarations: [
    OrderPaymentComponent,
    PaymentDetailsComponent,
    InputPromoComponent,
    CongratulationComponent,
    AddressFormComponent,
    OrderSingleStepComponent,
    FaqTooltipComponent,
    HdcInfoModalComponent,
    InputQuantityComponent,
    CableCartComponent,
    ShippingDetailsComponent,
    OrderPaymentComponent,
    PaymentDetailsComponent,
    PhonePipe,
    StateNamePipe,
    OrderConfirmedModalComponent,
    PopupMessageModalComponent,
    OrderPlanDetailsComponent,
    OrderPlanSummaryComponent,
    JobberConnectModalComponent,
  ],
  imports: [
    CommonModule,
    CommonLayoutModule,
    RecurlyModule,
    SelectModule,
    ReactiveFormsModule,
    HintModule,
    PriceModule,
    FormErrorModule,
    AppInputModule,
    NgxMaskModule,
    RouterModule,
    AnnualModule,
    BreakpointModule,
    FormsModule,
    ModalModule,
    MarkTouchedIfLengthGreaterThanModule,
    GoogleMapsModule,
    ModalModule,
    AngularSvgIconModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class OrderModule {}
