<div class="modal-content">
  <div class="header-wrap">
    <h3 class="modal-header">Order Confirmed!</h3>
  </div>

  <div class="modal-section">
    You’ll be getting several emails: An order confirmation; a welcome email; and once we ship your devices, an email
    with a shipment tracking link and instructions on how to get started.
  </div>

  <div class="force-jobber-container modal-section">
    <h3 class="modal-header">Connect your Force and Jobber accounts</h3>
    <img src="/assets/images/force-jobber.png" alt="Jobber Force Connect" class="force-jobber-img" />
  </div>
  <div class="modal-section">
    You need to allow Force Fleet Tracking to connect to your Jobber account to exchange data. To do so, ensure you
    disable pop-up blockers in your browser and then tap NEXT.
  </div>
  <div class="btn-wrapper">
    <button class="connect-btn" (click)="onNextClicked()">NEXT</button>
  </div>
</div>
