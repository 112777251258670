<div class="modal-content">
  <ng-container *ngIf="!hideOrderConfirmedMsg">
    <div class="header-wrap">
      <img src="/assets/images/mobile-app-img.webp" alt="mobile app" class="phone-img md-show" />
      <h3 class="modal-header">Order Confirmed!</h3>
    </div>

    <div class="modal-top-text">
      Thank you for your order. We've sent you an email with your order details and will ship your new device(s) within
      2 business days. You'll also get an email with a shipment tracking link and instructions on how to get started.
    </div>
  </ng-container>

  <div class="d-flex onboard">
    <img src="/assets/images/mobile-app-img.webp" alt="mobile app" class="phone-img md-hide" />
    <div>
      <h4 class="onboard-title">Get ready to onboard...</h4>
      <div>
        Make sure you download the Force Fleet Tracking mobile app. Not only will it make tracking and monitoring your
        fleet a breeze, you’ll also need it to install your devices when they arrive.
      </div>
    </div>
  </div>

  <a class="md-show download-btn" href="https://qrcodes.pro/MUqz3G">DOWNLOAD APP</a>

  <div class="scan md-hide">
    <div>Scan to get the app:</div>
    <img class="qr-code" src="/assets/images/app-qr-code.svg" alt="qr code" />
    <div class="d-flex">
      <img src="/assets/icons/apple-icon.svg" alt="app store icon" class="store-icon" />
      <img src="/assets/icons/google-store-icon.webp" alt="android store icon" class="store-icon" />
    </div>
  </div>
</div>
