<select
  #inputRef
  class="select-input"
  [ngClass]="cssClass"
  [disabled]="disabled || readonly"
  (click)="toggleDropdown()"
  (keydown)="handleKeydown($event)"
  (change)="onChangeHandler($event)"
  (blur)="onBlurHandler()"
  [name]="name"
>
  <ng-content></ng-content>
</select>
<svg-icon
  src="/assets/icons/caret-down.svg"
  class="caret-icon"
  [class.disabled]="disabled || readonly"
  [ngClass]="{ up: isOpen }"
></svg-icon>
<div class="error-icon"></div>
