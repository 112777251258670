import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-order-confirmed-modal',
  templateUrl: './order-confirmed-modal.component.html',
  styleUrls: ['./order-confirmed-modal.component.scss'],
})
export class OrderConfirmedModalComponent {
  @Input()
  public hideOrderConfirmedMsg = false;
}
