import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TForcePlanType, TPlanType } from 'src/app/core/models/iorder-state.model';
import { OrderStateService } from 'src/app/core/service/order-state.service';
import { planDeviceName } from 'src/app/utils/string';

@Component({
  selector: 'app-order-plan-summary',
  templateUrl: './order-plan-summary.component.html',
  styleUrls: ['./order-plan-summary.component.scss'],
})
export class OrderPlanSummaryComponent implements OnChanges {
  @Input()
  public isVendorSpecific = false;

  @Input()
  public sectionPlanType: TForcePlanType;

  public planType: TPlanType;

  public get totalDevicesPrice(): number {
    return this.orderState.totalDevicesPriceWithDiscount();
  }

  public get availablePromoDevices(): number {
    return this.orderState.availablePromoDevices;
  }

  public get totalSubscriptionPlanPrice(): number {
    if (this.isVendorSpecific) {
      if (this.planType === 'pro') {
        return this.orderState.totalVendorDashcamSubscriptionPrice();
      }
      if (this.planType === 'premium') {
        return this.orderState.totalVendorTrackerSubscriptionPrice();
      }
    }
    return this.orderState.totalVehiclesTrackingPriceWithDiscount();
  }

  public get devicesCount(): number {
    if (this.isVendorSpecific) {
      if (this.planType === 'pro') {
        return this.orderState.getNumberOfVendorDashcams();
      }
      if (this.planType === 'premium') {
        return this.orderState.getNumberOfVendorTrackers();
      }
    }
    return this.orderState.devicesCount();
  }

  public get planDeviceName(): string {
    return planDeviceName(this.planType);
  }

  constructor(public orderState: OrderStateService) {
    this.planType = this.orderState.stateValue().planType;
  }

  public ngOnChanges(sc: SimpleChanges): void {
    if (sc?.sectionPlanType) {
      this.planType = sc.sectionPlanType.currentValue;
    }
  }
}
